import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base } from "@atoms/text";
import { SupplierOrderPrepLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";

export const SupplierSendOrderLinePage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const [orderHeaders, setOrderHeaders] = useState<SupplierOrderPrepLine[]>([]);
  const { getOrderPreparationLines } = useSuppliers();
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );

  const orderColumns: Column<SupplierOrderPrepLine>[] = [
    {
      title: "Fournisseur",
      id: "codeFourn",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Raison-sociale",
      id: "raisSocial",
      render: (p) => <Base>TEST</Base>,
    },
  ];

  return (
    <Page>
      <>
        <Button onClick={() => setSendOrderModal({ open: true })}>
          Commande fournisseur: NUM COMMANDE
        </Button>
        <TableGridSwitch
          data={orderHeaders}
          onChangeMode={(v) => setGridMode({ activated: v })}
          gridMode={gridMode.activated}
          tableColumns={orderColumns}
          onRequestData={async () => {
            const res = await getOrderPreparationLines();
            if (res) setOrderHeaders(res);
            else setOrderHeaders([]);
          }}
          renderGrid={() => <> </>}
        />
      </>
    </Page>
  );
};
