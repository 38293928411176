import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, InfoSmall } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { formatTime } from "@features/utils/format/dates";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

type CommandClientModalType = {
  open: boolean;
  listCde: any[];
};

export const CommandClientModalAtom = atom<CommandClientModalType>({
  key: "CommandClientModalAtom",
  default: { open: false, listCde: [] as any[] },
});

export const CommandClientModal = () => {
  const { selectedReception } = useReception();
  const [ordersList, setOrdersList] = useState<any[]>([]);
  const [commandClientModal, setCommandClientModal] = useRecoilState(
    CommandClientModalAtom
  );

  const numCde: Column<any> = {
    title: "Commande",
    render: (el) => el.numCdeCli,
  };
  const infosClient: Column<any> = {
    title: "Infos client",
    render: (el) => (
      <div className="w-full flex flex-col gap-2 text-center">
        <Base className="w-full">{el.nomClient}</Base>
        <InfoSmall className="w-full">{el.codeCanalVente}</InfoSmall>
      </div>
    ),
  };
  const matchCode: Column<any> = {
    title: "Match code",
    render: (el) => el.matchCode,
  };

  const date: Column<any> = {
    title: "Date",
    render: (el) =>
      formatTime(el.dateCde as string, "fr-FR", {
        keepTime:
          new Date(el.dateCde as string).getSeconds() === 0 ? false : true,
        keepSeconds: false,
        keepDate: true,
      }),
  };
  const qte: Column<any> = {
    title: "Quantité",
    render: (el) => el.qteCde,
  };

  const handleClose = () => {
    setCommandClientModal({ open: false, listCde: [] });
  };

  useControlledEffect(() => {
    if (commandClientModal.listCde)
      setOrdersList(
        commandClientModal.listCde.filter((el) => el.numCdeCli !== "")
      );
  }, [commandClientModal]);

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={commandClientModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent
        title={"Information sur commande:" + selectedReception.numCde}
      >
        <div className="w-full">
          <Table
            emptyTabText={"Aucune commande associée"}
            columns={[numCde, infosClient, matchCode, date, qte]}
            data={ordersList || []}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
